<template>
  <div v-if="video" class="px-3 pt-3 p_b__">
    <b-card>
      <b-container ref="defaultContainer" fluid class="bv-example-row">
        <b-row>
          <b-col cols="12" lg="4" xl="5" class="p-0">
            <video-selector></video-selector>
          </b-col>

          <b-col cols="12" md="6" lg="4" :xl="jsonVersion < 6 ? '2' : '3'">
            <template>
              <div class="content-center">
                <h1>
                  <!-- {{ $t("page.results.score.globalPerformance.old.title") }} -->
                  {{ viewerDisplay }}
                </h1>
                <h3 style="font-weight: 300">
                  {{ $t(`page.results.score.contentEfficiency`) }}
                </h3>
                <h1 class="efficiency-perc">
                  {{ parseInt(video.in_context_metrics.efficiency * 100) }}%
                </h1>
              </div>
            </template>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            :xl="jsonVersion < 6 ? '5' : '4'"
            style="margin: 0; padding: 0"
          >
            <!-- JSON VERSION < 6 -->
            <template v-if="jsonVersion >= 6">
              <p>
                {{ $t(`page.results.score.contentEfficiencyText`) }}
              </p>
              <h4>{{ globalPerformanceTitle }}</h4>
              <p>
                {{ globalPerformanceText }}
              </p>
              <p>
                {{ $t(`page.results.score.ecmpImpact`) }}
              </p>
              <b-button
                @click="scrollToECMP()"
                class="mb-4 px-5 py-2"
                variant="red"
                style="font-weight: 700; font-size: 0.8rem"
              >
                {{ $t(`page.results.score.calculateECMP`) }}
              </b-button>
              <hr />
              <b-row>
                <b-col
                  ><div class="below-norm-square"></div>
                  {{ $t(`page.results.score.belowNorm`) }}</b-col
                >
                <b-col>
                  <div class="close-norm-square"></div>
                  {{ $t(`page.results.score.closeNorm`) }}</b-col
                >
                <b-col>
                  <div class="above-norm-square"></div>
                  {{ $t(`page.results.score.aboveNorm`) }}</b-col
                >
              </b-row>
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="ml-2 mt-5">
              <h3>
                {{
                  $t("page.results.score.behavioursMetrics.behavioursMetrics")
                }}
              </h3>
            </b-row>
            <b-row :key="jsonVersion" class="">
              <b-col xl="3" lg="6" md="6" sm="12" class="mt-5">
                <half-circle-score-bar
                  metric=""
                  :title="
                    $t(
                      `page.results.score.behavioursMetrics.firstTimeView.text`
                    )
                  "
                  :tooltip="
                    $t(
                      `page.results.score.behavioursMetrics.firstTimeView.tooltip`
                    )
                  "
                  :norm="0"
                  :score="
                    parseInt(
                      video.in_context_metrics.percentage_watched_1s * 100
                    )
                  "
                ></half-circle-score-bar>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" class="mt-5">
                <half-circle-score-bar
                  metric="something"
                  :title="
                    $t(`page.results.score.behavioursMetrics.multiView.text`)
                  "
                  :tooltip="
                    $t(`page.results.score.behavioursMetrics.multiView.tooltip`)
                  "
                  :norm="0"
                  :score="
                    video.percentage_replays ? video.percentage_replays : 0
                  "
                  color="#ff0000"
                ></half-circle-score-bar>
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" class="mt-5">
                <!-- Change it to Retention graph maybe? -->
                <GraphVertical
                  :percentage="this.percentageWatched"
                  :title="
                    $t(`page.results.score.behavioursMetrics.retantion.text`)
                  "
                  :tooltip-info="
                    $t(`page.results.score.behavioursMetrics.retantion.tooltip`)
                  "
                >
                </GraphVertical>
              </b-col>
              <b-col class="text-center mt-5" xl="3" lg="6" md="6" sm="12">
                <title-info
                  condensed
                  :info="
                    $t(`page.results.score.behavioursMetrics.viwedTime.tooltip`)
                  "
                  justify="center"
                  size="medium"
                  >{{
                    $t(`page.results.score.behavioursMetrics.viwedTime.text`)
                  }}</title-info
                >
                <h2 style="color: #7f7f7f">
                  {{ video.in_context_metrics.watch_time.toFixed(2) }} sec
                </h2>
                <title-info
                  condensed
                  justify="center"
                  :info="
                    $t(
                      `page.results.score.behavioursMetrics.interactions.tooltip`
                    )
                  "
                  size="medium"
                  class="interactions"
                >
                  {{
                    $t(`page.results.score.behavioursMetrics.interactions.text`)
                  }}
                </title-info>
                <h2 style="color: #7f7f7f">
                  {{ video.in_context_metrics.interactions || "-" }}
                </h2>
              </b-col>
            </b-row>
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row class="ml-2 mt-5">
              <h3>{{ $t(`page.results.score.attentionEmotionMetrics`) }}</h3>
            </b-row>

            <b-row :key="jsonVersion" class="justify-content-center">
              <!-- Change it with metric-score component when json is ready -->
              <b-col
                v-for="(item, index) in metricsAttentionEmotion"
                :key="index"
                class="text-center m-3 mt-5"
                :md="5"
                :sm="12"
                :lg="3"
                :xl="2"
              >
                <title-info
                  size="small"
                  justify="center"
                  condensed
                  style="max-height: 10px"
                  :info="item.tooltip"
                >
                  {{ item.title }}
                </title-info>
                <h1 :style="{ color: item.color }">{{ item.value }}</h1>
                <p v-if="item.norm">Norm {{ item.norm }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-row class="ml-2 mt-5">
              <h3>{{ $t(`page.results.score.ecmp.calculateECMP.title`) }}</h3>
            </b-row>
            <b-row class="ml-2 mt-5">
              <b-col lg="6" md="7" xl="6" sm="12">
                <p>
                  {{ $t(`page.results.score.ecmp.calculateECMP.row1`) }}
                </p>
                <p
                  v-html="$t(`page.results.score.ecmp.calculateECMP.row2`)"
                ></p>
                <p
                  v-html="$t(`page.results.score.ecmp.calculateECMP.row3`)"
                ></p>
                <p
                  v-html="$t(`page.results.score.ecmp.calculateECMP.row4`)"
                ></p>
              </b-col>
              <b-col lg="6" md="5" xl="6" sm="12" ref="ecmpCalculator">
                <ecmpCalculator
                  :efficiency="video.efficiency ? video.efficiency : 3"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TitleInfo from "../components/Results/Score/TitleInfo.vue";
// import GaugeChart from "../components/Results/Score/GaugeChart.vue";
import HalfCircleScoreBar from "../components/Results/Score/HalfCircleScoreBar.vue";
import VideoSelector from "../components/Results/VideoSelector.vue";
import GraphVertical from "../components/Results/Score/GraphVertical.vue";
import ecmpCalculator from "../components/Results/ecmpCalculator.vue";
// import AgeGender from "../components/Results/Score/AgeGender.vue";
export default {
  components: {
    VideoSelector,
    TitleInfo,
    HalfCircleScoreBar,
    GraphVertical,
    ecmpCalculator,
    // GaugeChart,
    // AgeGender
  },
  data() {
    return {
      displayMore: false,
      metricsAttentionEmotion: [
        {
          title: this.$t("page.results.score.globalPerformance.title"),
          tooltip: this.$t("page.results.score.globalPerformance.tooltip"),
          norm: "68%",
          value: "-",
          color: "",
          name: "globalPerformance",
        },
        {
          title: this.$t("page.results.score.valence.title"),
          tooltip: this.$t("page.results.score.valence.tooltip"),
          value: "-",
          norm: "",
          color: "",
          name: "valence",
        },
        {
          title: this.$t("page.results.score.attention.title"),
          tooltip: this.$t("page.results.score.attention.tooltip"),
          norm: `-`,
          value: "-",
          color: "",
          name: "attention",
        },
        {
          title: this.$t("page.results.score.attentionSpan.title"),
          tooltip: this.$t("page.results.score.attentionSpan.tooltip"),
          norm: `-`,
          value: "-",
          color: "",
          name: "attentionSpan",
        },
        {
          title: this.$t("page.results.score.engagement.title"),
          tooltip: this.$t("page.results.score.engagement.tooltip"),
          norm: `-`,
          value: "-",
          color: "",
          name: "engagement",
        },
      ],
    };
  },
  created() {
    if (!this.showMetrics) {
      this.$router.replace({ name: "graph" });
    }
    this.setMetricsAttentionEmotion();
  },
  computed: {
    ...mapState("analysis", ["comparison", "norms", "jsonVersion"]),
    ...mapGetters("analysis", [
      "video",
      "globalPerformanceTitle",
      "globalPerformanceText",
      "globalPerformanceColor",
      "globalPerformanceResponse",
      "valence",
      "ValenceText",
    ]),

    ...mapState("project", ["activeProject"]),

    showMetrics() {
      return this.activeProject.step !== -1;
    },
    percentageWatched() {
      return [
        {
          percentage: (
            this.video.in_context_metrics.percentage_watched_1s * 100
          ).toFixed(0),
          secondsWatched: 1,
        },
        {
          percentage: (
            this.video.in_context_metrics.percentage_watched_3s * 100
          ).toFixed(0),
          secondsWatched: 3,
        },
        {
          percentage: (
            this.video.in_context_metrics.percentage_watched_5s * 100
          ).toFixed(0),
          secondsWatched: 5,
        },
      ];
    },
    viewerDisplay() {
      switch (this.activeProject.viewer) {
        case "youtube":
          return "Youtube";
        case "facebook":
          return "Facebook";
        case "tiktok":
          return "TikTok";
        case "instagram":
          return "Istagram";
        case "standard":
          return "Standard";
        default:
          return this.activeProject.viewer;
      }
    },
  },
  methods: {
    onClick(displayMore) {
      this.displayMore = displayMore;
      this.$nextTick(() => {
        const container = this.$refs.showMoreContainer;
        const showMoreButton = this.$refs.showMoreButton;
        if (showMoreButton) {
          console.log(showMoreButton.getBoundingClientRect().top);
          window.scrollTo({
            top: 500,
            behavior: "smooth",
          });
        }
        if (container) {
          window.scrollTo({
            top: container.getBoundingClientRect().bottom,
            behavior: "smooth",
          });
        }
      });
    },
    scrollToECMP() {
      this.$nextTick(() => {
        const ecmpCalculator = this.$refs.ecmpCalculator;
        if (ecmpCalculator) {
          window.scrollTo({
            top: ecmpCalculator.getBoundingClientRect().top,
            behavior: "smooth",
          });
        }
      });
    },
    calcColor(metric, score) {
      score = parseFloat(score);
      if (metric === this.metricsAttentionEmotion[3].name) {
        if (score < 5.0) {
          return "#ff0000";
        } else if (score < 12.0) {
          return "#f3ad57";
        } else {
          return "#7aae30";
        }
      } else {
        if (score < 40) {
          return "#ff0000";
        } else if (score < 70) {
          return "#f3ad57";
        } else {
          return "#7aae30";
        }
      }
    },
    setMetricsAttentionEmotion() {
      this.metricsAttentionEmotion[0].value = `${this.video.globalPerformance}%`; // global index perc
      this.metricsAttentionEmotion[1].value =
        this.video.valence > 1 ? `POSITIVE` : `NEGATIVE`; // valence
      this.metricsAttentionEmotion[2].value = `${this.video.attention}%`; // attention, perc
      this.metricsAttentionEmotion[3].value = `${this.video.attentionSpan} sec`; // attention span, sec
      this.metricsAttentionEmotion[4].value = `${this.video.engagement}%`; // engament, perc

      this.metricsAttentionEmotion[0].norm = `${this.norms.globalPerformance}%`; // global index perc
      this.metricsAttentionEmotion[1].norm = ""; // valence
      this.metricsAttentionEmotion[2].norm = `${this.norms.attention}%`; // attention, perc
      this.metricsAttentionEmotion[3].norm = `${this.norms.attentionSpan} sec`; // attention span, sec
      this.metricsAttentionEmotion[4].norm = `${this.norms.engagement}%`; // engament, perc

      this.metricsAttentionEmotion[0].color = this.calcColor(
        this.metricsAttentionEmotion[0].name,
        this.metricsAttentionEmotion[0].value
      ); // global index perc
      this.metricsAttentionEmotion[1].color =
        this.video.valence > 1 ? "#7aae30" : "#ff0000"; // valence
      this.metricsAttentionEmotion[2].color = this.calcColor(
        this.metricsAttentionEmotion[2].name,
        this.metricsAttentionEmotion[2].value
      ); // attention, perc
      this.metricsAttentionEmotion[3].color = this.calcColor(
        this.metricsAttentionEmotion[3].name,
        this.metricsAttentionEmotion[3].value
      ); // attention span, sec
      this.metricsAttentionEmotion[4].color = this.calcColor(
        this.metricsAttentionEmotion[4].name,
        this.metricsAttentionEmotion[4].value
      ); // engament, perc
    },
  },
};
</script>

<style scoped>
.card {
  min-height: 500px;
  min-width: 300px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (min-width: 769px) and (max-width: 1334px) {
  .card {
    min-height: 560px;
  }
}

@media screen and (max-width: 992px) {
  .col-12 {
    margin-top: 2rem;
  }
}
.col-12:not(:last-child) .card {
  margin-right: 2rem;
}

.interactions {
  margin-top: 120px;
}
.above-norm {
  color: #7aae30;
}
.below-norm {
  color: #ff0000;
}
.close-norm {
  color: #f3ad57;
}
.above-norm-square {
  width: 20px;
  height: 20px;
  background-color: #7aae30;
  margin-right: 12px;
  float: left;
}
.below-norm-square {
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  margin-right: 12px;
  float: left;
}
.close-norm-square {
  width: 20px;
  height: 20px;
  background-color: #f3ad57;
  margin-right: 12px;
  float: left;
}
.content-center {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0px 10px 20px 20px;
  h1 {
    font-weight: bolder;
  }
  .efficiency-perc {
    color: #7aae30;
    font-size: 80px;
  }
}
</style>
